/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    ol: "ol",
    li: "li",
    h2: "h2"
  }, _provideComponents(), props.components), {LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    className: "c-md-h1",
    id: "what-to-do-if-my-hearing-aids-are-not-charging",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-to-do-if-my-hearing-aids-are-not-charging",
    "aria-label": "what to do if my hearing aids are not charging permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What to do if my hearing aids are not charging"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If your ", React.createElement(_components.a, {
    href: "/hearing-aids",
    className: "c-md-a"
  }, "hearing aids"), " are not charging, there could be several potential reasons for this issue. Here's a step-by-step guide to troubleshoot the problem:"), "\n", React.createElement(_components.ol, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Check the basics:\nMake sure that the charging cables and connectors are properly connected to both the charging station and your hearing aids. Confirm that the charging station is plugged into a functional power outlet."), "\n"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Clean the charging contacts:\nOver time, dirt, debris, or oil can accumulate on the charging contacts of both your hearing aids and the charging station. Gently clean these contacts using a soft, dry cloth to ensure a proper connection."), "\n"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Inspect for damage:\nExamine the charging cables, connectors, and the charging station for any visible damage. If you notice any frayed wires, bent pins, or other issues, these could be preventing the charging process."), "\n"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Reset the charging station:\nSome hearing aid charging stations have a reset button or process. Consult your hearing aid's user manual to find out if there's a reset option and how to perform it."), "\n"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Try a different outlet:\nSometimes, the power outlet might be faulty. Try plugging the charging station into a different outlet to see if that resolves the issue."), "\n"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Check battery placement:\nIf your hearing aids have removable batteries, ensure that the batteries are properly inserted and securely in place."), "\n"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Check for software updates:\nSome modern hearing aids can be updated through their associated apps or software. Check if there are any available updates for your hearing aids that could potentially resolve charging issues."), "\n"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Contact customer support:\nIf you've tried the above steps and your hearing aids still won't charge, it's time to contact the manufacturer's customer support or your audiologist. They can provide specific troubleshooting steps for your particular model and might guide you through more advanced solutions."), "\n"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Battery replacement:\nIf your ", React.createElement(_components.a, {
    href: "/hearing-aids/technology/rechargeable/",
    className: "c-md-a"
  }, "hearing aids use rechargeable batteries"), ", it's possible that the batteries themselves have reached the end of their lifespan and can no longer hold a charge. In this case, you might need to replace the rechargeable batteries."), "\n"), "\n"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "contact-hearcom-hearing-specialists",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#contact-hearcom-hearing-specialists",
    "aria-label": "contact hearcom hearing specialists permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Contact hear.com Hearing Specialists"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you're still facing issues or have doubts, remember that ", React.createElement(_components.a, {
    href: "/",
    className: "c-md-a"
  }, "hear.com"), " has a team of hearing specialists who can provide personalized assistance and guidance. They are available to answer your questions, address your concerns, and offer the support you need to get your hearing aids back on track. Additionally, they can provide information about specific hearing aid models, features, and options that might suit your needs. Don't hesitate to reach out to their experts for expert advice tailored to your situation."), "\n", React.createElement(LandingPageCta, {
    copy: "Contact a Hearing Specialist",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
